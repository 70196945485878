<script setup lang="ts">
import type { ISearchProcedureResponseItem } from '@/types/types'
import { Cookie, EventName } from '@/types/enums'

const props = defineProps({
  id: {
    type: String,
    default: 'search-procedure-id',
  },
  initialSelected: {
    type: Array,
    default: () => [],
  },
  buttonTitle: {
    type: String,
    default: 'Buscar exames',
  },
  noButton: {
    type: Boolean,
    default: false,
  },
  noBadge: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['searchProcedures', 'removeProcedure'])

const locationParam = useCookie(Cookie.LocationParam)

const term = ref('')
const dTerm = refDebounced(term, 300, { maxWait: 600 })
const selected = ref<ISearchProcedureResponseItem[]>([])
const { procedures } = useProcedures()

const animation = reactive({
  procedures: ['Hemograma', 'Espermograma', 'Papanicolau', 'Creatinina', 'Ultrassom Morfológico', 'Ureia'],
  index: 0,
})

const { showOcrModal } = useOcr()
const { pending, data: searched } = useSearchProcedure(dTerm, props.id, selected)

const placeholder = computed(() => selected.value.length ? 'Você tem mais exames? Digite aqui' : 'Buscar')

function removeProcedure(procedure: ISearchProcedureResponseItem) {
  selected.value = selected.value.filter((selected: any) => selected.slug !== procedure.slug)

  emit('removeProcedure', procedure)
}

function searchProcedures() {
  if (locationParam.value?.length) {
    return navigateTo(`/${locationParam.value}/exames?exames=${useProcedureSlug().value?.join(',')}`)
  }

  if (!selected.value.length) {
    return showOcrModal.value = true
  }

  const slugSelected = selected.value.map(({ slug }: any) => slug)

  selected.value = []

  track(EventName.Search, {
    cityId: useLocation().value?.id,
    citySlug: useLocation().value?.slug,
    procedureCount: slugSelected.length,
  })

  emit('searchProcedures', slugSelected)
}

function procedureNameShow(name: string): string {
  return name.length > 45 ? `${name.slice(0, 45)}...` : name
}

function openOCR() {
  showOcrModal.value = true
}

useIntervalFn(() => {
  if ((animation.index + 1) >= animation.procedures.length) {
    animation.index = 0

    return
  }

  animation.index++
}, 2000)

onNuxtReady(() => {
  if (props.initialSelected)
    selected.value = props.initialSelected as ISearchProcedureResponseItem[]
})
</script>

<template>
  <div
    class="relative flex gap-3 flex-nowrap flex-col xl:flex-row"
  >
    <div
      class="bg-white shadow-md relative transition-all xl:min-w-[465px] w-full xl:flex-1"
      :class="searched.length ? 'rounded-t-lg' : 'rounded-md'"
    >
      <div class="flex items-center justify-between group px-3">
        <label for="search" class="sr-only">Input para a busca de exames</label>

        <Icon
          :name="pending ? 'mingcute:loading-fill' : 'mdi:magnify'"
          class="text-primary-500"
          :class="{ 'animate-spin': pending }"
          height="24"
          width="24"
        />

        <transition
          v-if="!selected.length && !term.length"
          name="slide-fade"
          enter-active-class="animate__animated animate__flipInX animate__faster"
          leave-active-class="animate__animated animate__flipOutX animate__faster"
          mode="out-in"
        >
          <div
            :key="animation.procedures[animation.index]"
            class="absolute ml-[80px] text-xs xl:text-sm text-primary-500 cursor-pointer"
            @click="openOCR"
          >
            {{ animation.procedures[animation.index] }}
          </div>
        </transition>

        <button
          id="search"
          data-testid="search-procedure-sidesheet-button"
          class="w-full px-4 py-[10px] xl:text-sm text-primary-500 text-sm focus-visible:outline-0 text-left"
          @click.prevent="openOCR"
        >
          {{ placeholder }}
        </button>

        <button @click="term = ''">
          <Icon
            v-if="term.length"
            name="mdi:close"
            class="text-primary-500 cursor-pointer"
            height="24"
            width="24"
          />
        </button>
      </div>
    </div>

    <div
      v-if="procedures?.length && !noBadge"
      class="flex flex-col gap-2 lg:hidden"
    >
      <div
        v-for="({ procedure }, index) in procedures.slice(0, 3)"
        :key="`badge-${index}`"
        class="animate__animated animate__fadeInDown word-break-all w-full rounded-full
          flex items-center bg-primary-50 py-2 px-3 cursor-pointer"
        @click="removeProcedure(procedure)"
      >
        <span
          class="text-xs whitespace-nowrap text-primary-400"
        >
          {{ procedureNameShow(procedure.name) }}
        </span>
      </div>
      <div
        v-if="procedures.length > 3"
        class="animate__animated animate__fadeInDown word-break-all w-full rounded-full
          flex items-center bg-primary-50 py-2 px-3 cursor-pointer"
      >
        <span
          class="text-xs whitespace-nowrap text-primary-400"
        >
          {{ `+${procedures.length - 3} Exame${(procedures.length - 3) > 1 ? 's' : ''}` }}
        </span>
      </div>
    </div>

    <Button
      v-if="!noButton"
      aria-label="Botão para abrir aba para buscar exames."
      aria-labelledby="Botão para abrir aba para buscar exames."
      class="h-10 w-full text-nowrap text-sm bg-secondary-300 hover:bg-secondary-400
        text-white font-sora font-semibold rounded-md py-3 px-5 cursor-pointer"
      :disabled="!procedures?.length"
      @click="searchProcedures"
    >
      {{ buttonTitle }}
    </Button>
  </div>
  <div
    v-if="procedures?.length && !noBadge"
    class="hidden lg:flex gap-2 mt-4"
  >
    <div
      v-for="({ procedure }, index) in procedures.slice(0, 3)"
      :key="`badge-${index}`"
      class="animate__animated animate__fadeInDown word-break-all w-fit rounded-full flex items-center bg-primary-50 py-2 px-3 cursor-pointer"
      @click="removeProcedure(procedure)"
    >
      <span
        class="text-xs whitespace-nowrap text-primary-400 max-w-[180px] text-ellipsis overflow-hidden"
      >
        {{ procedureNameShow(procedure.name) }}
      </span>
    </div>
    <div
      v-if="procedures.length > 3"
      class="animate__animated animate__fadeInDown word-break-all w-full rounded-full
          flex items-center bg-primary-50 py-2 px-3 cursor-pointer"
    >
      <span
        class="text-xs whitespace-nowrap text-primary-400"
      >
        {{ `+${procedures.length - 3} Exame${(procedures.length - 3) > 1 ? 's' : ''}` }}
      </span>
    </div>
  </div>
</template>
