<script lang="ts" setup>
function redirect() {
  return navigateToWithLocation('/buscar-exames')
}
</script>

<template>
  <div class="flex lg:flex-row flex-col items-center gap-4">
    <button class="relative flex items-center gap-2 p-3 text-neutral-100 bg-bg-50 rounded-md w-full" @click="redirect">
      <span class="sr-only">Botão para buscar exames médicos.</span>
      <Icon name="mdi:magnify" class="size-4" />
      <span class="text-sm leading-5">Busque pelo nome ou sinônimo</span>
    </button>

    <Button class="whitespace-nowrap w-full lg:w-fit" @click="redirect">Buscar exames</Button>
  </div>
</template>
