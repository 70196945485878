<script setup lang="ts">
import { EventName } from '@/types/enums'

type TColor = 'primary' | 'secondary' | 'tertiary' | 'information'

defineProps({
  backlink: {
    type: String,
    default: '/',
  },
  color: {
    type: String as PropType<TColor>,
    default: 'primary',
  },
  image: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  withBreadcrumb: {
    type: Boolean,
    default: false,
  },
  withOcr: {
    type: Boolean,
    default: false,
  },
  withSearch: {
    type: Boolean,
    default: false,
  },
})

const { removeSlug, procedures } = useProcedures()
const initialSelected = computed(() => {
  if (!procedures.value.length || procedures.value.length === 1)
    return null as never

  return procedures.value.map(({ procedure: { name, slug, id } }: any) => ({ name, slug, id }))
})

function handleRemoveProcedure(procedure: any) {
  track(EventName.ExamRemove, {
    procedureDisplayId: procedure.id,
    displayName: procedure.name,
  })

  removeSlug(procedure.slug)
}
</script>

<template>
  <section
    class="pt-20 relative z-0 w-full bg-gradient-to-b from-[10%] to-[90%] justify-center lg:justify-between overflow-hidden"
    :class="[
      { 'from-primary-400 to-primary-400': color === 'primary' },
      { 'from-secondary-400 to-secondary-400': color === 'secondary' },
      { 'from-tertiary-400 to-tertiary-400': color === 'tertiary' },
      { 'from-information-500 to-information-200': color === 'information' },
    ]"
  >
    <IconIsotipo
      v-if="isTrevo()"
      class="absolute top-0 h-full"
      :class="[
        { 'text-primary-500 md:text-primary-500': color === 'primary' },
        { 'text-secondary-500 md:text-secondary-500': color === 'secondary' },
        { 'text-tertiary-500 md:text-tertiary-500': color === 'tertiary' },
        { 'text-information-300 md:text-information-20': color === 'information' },
      ]"
    />
    <div class="lg:container lg:mx-auto flex flex-col relative">
      <Breadcrumbs v-if="isNewJourney()" is-in-banner />

      <NuxtLink
        v-else-if="withBreadcrumb"
        :to="backlink"
        class="text-white relative md:absolute flex items-center gap-2 py-3 mt-6 ml-4 md:ml-0 font-semibold z-20"
      >
        <Icon name="mdi:arrow-left" class="w-5 h-5 md:w-5 md:h-5" />
        Início
      </NuxtLink>

      <div
        class="relative z-10  px-5 lg:px-0 grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:gap-14 items-center"
      >
        <HeroBannerSpotlight
          v-if="image"
          class="absolute top-0 right-0 h-[120%] md:h-full w-full md:w-1/2"
          :class="[
            { 'text-primary-300 md:text-primary-200': color === 'primary' },
            { 'text-secondary-300 md:text-secondary-300': color === 'secondary' },
            { 'text-tertiary-400 md:text-tertiary-300': color === 'tertiary' },
            { 'text-information-200 md:text-information-80': color === 'information' },
          ]"
        />

        <div
          class="w-full flex flex-col justify-center pt-8"
          :class="withOcr ? 'pb-[9.5rem] md:pb-8' : 'pb-8'"
        >
          <h1
            class="relative text-center md:text-left font-sora font-semibold text-white text-4xl lg:text-5xl mb-4 leading-10 lg:mb-2"
          >
            <slot name="title" />
          </h1>

          <h2
            class="relative text-center md:text-left text-white text-xl mb-6"
          >
            <slot name="subtitle" />
          </h2>

          <div v-if="withSearch">
            <ButtonSearchProcedure v-if="isNewJourney()" />
            <WSearchProcedure
              v-else
              id="banner-saude-10"
              :initial-selected="initialSelected"
              @search-procedures="navigateToWithLocation(`/exames?exames=${useProcedureSlug().value?.join(',')}`)"
              @remove-procedure="handleRemoveProcedure"
            />
          </div>
        </div>

        <div
          class="hidden md:flex w-full h-full justify-center items-end relative"
          :class="compact ? 'lg:h-[300px]' : 'lg:h-[478px]'"
        >
          <Img
            v-if="image"
            :src="image"
            alt="Imagem de pessoas no banner."
            :width="width"
            :height="height"
            class="hidden md:block w-full max-h-full absolute bottom-0 object-contain pt-4"
            lazy-load
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
</template>
