<template>
  <svg
    width="785"
    height="480"
    viewBox="0 0 785 480"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_3704_3853)">
      <ellipse
        cx="392.206"
        cy="336.016"
        rx="234.415"
        ry="218.116"
        transform="rotate(24.1891 392.206 336.016)"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_3704_3853"
        x="0.387207"
        y="-44.9707"
        width="783.638"
        height="761.974"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_3704_3853" />
      </filter>
    </defs>
  </svg>
</template>
